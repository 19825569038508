import React, { useState } from 'react'
import { IFormProps } from '../../lib/types'
import { FormikProps, Formik } from 'formik'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { Input, Button, Flex, SelectDropdown, PendingIcon, Anchor } from '../../lib/components'
import { COUNTRIES } from '../../lib/utils/message'
import { useBreakpoint } from '../../lib/utils/hooks'
import { XL } from '../../lib/utils/constants'

const GeneralForm = ({ initialValues, handleFormSubmit, validationSchema }: IFormProps) => {
  const { breakpoint } = useBreakpoint()
  const [isValidPhoneNumberValue, setIsValidPhoneNumberValue] = useState(true)
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {(formik) => {
        const {
          values,
          touched,
          errors,
          dirty,
          handleSubmit,
          setFieldValue,
        }: FormikProps<typeof initialValues> = formik
        return (
          <>
            <div className='rm-details-container'>
              <div className='payment-warning-container-full mt-0'>
                <PendingIcon classes={['info-icon']} width='75px' height='24px' />
                <div>
                  For any assistance, contact our support team at
                  <Anchor
                    variant='basic-anchor'
                    classes={['ml-4', 'email-link']}
                    href='mailto:support@credibila.com'
                    text='support@credibila.com'
                  />
                  . You can also reach out to your dedicated Relationship Manager, Dominika Maria,
                  at
                  <Anchor
                    variant='basic-anchor'
                    classes={['ml-4', 'email-link']}
                    href='mailto:dominika.maria@credibila.com'
                    text='dominika.maria@credibila.com'
                  />{' '}
                  for personalized support. Our working hours are 10 AM to 6 PM Dubai time (GST).
                </div>
              </div>
            </div>
            <form>
              <Flex variant={breakpoint && breakpoint > XL ? 'space-between' : 'column'}>
                <div className='general-input-container'>
                  <Input
                    labelText='Your name'
                    name='name'
                    placeholder='User Investor'
                    type='text'
                    required
                    value={values.name}
                    handleChange={(e) => setFieldValue('name', e.target.value)}
                    dirty={dirty}
                    errors={{
                      name: !!errors && errors.name ? errors.name : null,
                    }}
                    touched={{ name: !!touched && !!touched.name }}
                  />
                </div>
                <div
                  className={`general-input-container ${
                    breakpoint && breakpoint > XL ? '' : 'mt-36'
                  }`}
                >
                  <span className='general-input-label'> Country of residence </span>
                  <SelectDropdown
                    name='countryOfResidence'
                    id='countryOfResidenceId'
                    classes={['general-input-select']}
                    formId=''
                    list={COUNTRIES}
                    value={values.countryOfResidence}
                    handleChange={(val: any) => setFieldValue('countryOfResidence', val)}
                    dirty={dirty}
                    errors={{
                      countryOfResidence:
                        !!errors && errors.countryOfResidence ? errors.countryOfResidence : null,
                    }}
                    touched={{
                      countryOfResidence: !!touched && !!touched.countryOfResidence,
                    }}
                  />
                </div>
              </Flex>
              <Flex
                classes={['mt-32']}
                variant={breakpoint && breakpoint > XL ? 'space-between' : 'column'}
              >
                <div className='general-input-container'>
                  <Input
                    labelText='Email'
                    name='email'
                    placeholder='user@email.com'
                    type='email'
                    disabled
                    required
                    value={values.email}
                  />
                </div>
                <div
                  className={`general-input-container ${
                    breakpoint && breakpoint > XL ? '' : 'mt-36'
                  }`}
                >
                  <span className='general-input-label'> Mobile phone </span>
                  <PhoneInput
                    international
                    country={initialValues.countryCode}
                    placeholder='Enter phone number'
                    value={values.phoneNumber}
                    onChange={(val: any) => {
                      if (val && isValidPhoneNumber(val)) {
                        setFieldValue('phoneNumber', val)
                        setIsValidPhoneNumberValue(true)
                      } else setIsValidPhoneNumberValue(false)
                    }}
                  />
                  {!isValidPhoneNumberValue && (
                    <span className='general-input-error'> Invalid Mobile Number </span>
                  )}
                </div>
              </Flex>
              <Button
                text='Save changes'
                classes={['auto-btn-width', 'button-top-margin']}
                variant='primary-btn'
                handleClick={handleSubmit}
              />
            </form>
          </>
        )
      }}
    </Formik>
  )
}

export { GeneralForm }
