import { axiosInstance } from '../../services'
import { ADD_CRM_EVENT, UPDATE_CRM } from '../../services/endpoints'

export enum EngagementEvents {
  logged_in = 'Logged in',
  visited_home = 'Visited Home page',
  visited_opportunity = 'Visited opportunity',
  investment_started = 'Started investment',
  agreement_signed = 'Agreement signed',
  investment_sent_for_approval = 'Investment sent for approval',
}

export async function updateCrm(
  fieldName: string,
  fieldData: string,
  headers: { [key: string]: string },
): Promise<void> {
  try {
    await axiosInstance.post(
      UPDATE_CRM,
      {
        fieldName,
        fieldData,
      },
      {
        headers: headers,
      },
    )
  } catch (error: any) {
    return error.response
  }
}

export async function logCrmEvent(
  eventName: EngagementEvents,
  headers: { [key: string]: string },
  eventData?: string,
): Promise<void> {
  try {
    await axiosInstance.post(
      ADD_CRM_EVENT,
      {
        eventName,
        eventData,
      },
      {
        headers: headers,
      },
    )
  } catch (error: any) {
    return error.response
  }
}
