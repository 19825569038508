/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import {
  Button,
  Flex,
  CTABanner,
  ApprovedIcon,
  DeniedIcon,
  PendingIcon,
} from '../../lib/components'
import { KYCProps } from '../../lib/types'
import { CONTACT_US, GET_IN_TOUCH_WITH_ADMIN, QUESTION_FOR_KYC } from '../../lib/utils/message'
import { ContactUsModal } from '../../lib/components/ContactUsModal'
import { SumSub } from './kyc-sumsub'
import './kyc.scss'

const KYCPresenter = ({ kycStatusData, getKYC, noOfKyc }: KYCProps) => {
  const MAX_RETRY = 3
  const params = useParams()
  const { profileTab } = params
  const [showContactUsModal, setShowContactUsModal] = useState(false)
  const handleClickModal = () => {
    setShowContactUsModal((state) => !state)
  }

  return (
    <>
      <div className={profileTab === 'kyc' ? 'kyc-container-profile' : 'kyc-container'}>
        {profileTab === 'kyc' && (
          <div className='kyc-status-container'>
            <span className='kyc-status-container-label'>Your current status</span>
            <span
              className={`kyc-status-container-status-${kycStatusData}`}
              data-testid='kyc-status-value'
            >
              {kycStatusData === 'NOT_STARTED' && (
                <>
                  <PendingIcon height='21' width='21' /> Not Started
                </>
              )}
              {kycStatusData === 'INITIATED' && (
                <>
                  <PendingIcon height='21' width='21' /> Initiated
                </>
              )}
              {kycStatusData === 'PENDING' && (
                <>
                  <PendingIcon height='21' width='21' /> Sent For Approval
                </>
              )}
              {kycStatusData === 'REJECTED' && (
                <>
                  <DeniedIcon height='21' width='21' /> Rejected
                </>
              )}
              {kycStatusData === 'RESUBMIT' && (
                <>
                  <DeniedIcon height='21' width='21' /> Resubmit
                </>
              )}
              {kycStatusData === 'APPROVED' && (
                <>
                  <ApprovedIcon height='21' width='21' /> Approved
                </>
              )}
            </span>
          </div>
        )}
        <div className='kyc-heading'>
          {kycStatusData === 'APPROVED' && (
            <div className='kyc-sub-heading-text-container-note'>
              <p className='basic kyc-sub-heading-text-note'>
                You can now go ahead to browse through{' '}
                <Link className='kyc-link' to={'/opportunities'}>
                  opportunities
                </Link>
                .
              </p>
            </div>
          )}
          {kycStatusData === 'REJECTED' && noOfKyc >= MAX_RETRY && (
            <div className='kyc-sub-heading-text-container' data-testid='kyc-text-maximum-tries'>
              <p className='kyc-sub-heading-text-disclaimer'>
                You have reached the maximum number of retries for KYC. Please contact the Credibila
                team at support@credibila.com.
              </p>
            </div>
          )}
          {((kycStatusData === 'REJECTED' && noOfKyc < MAX_RETRY) ||
            kycStatusData === 'RESUBMIT') && (
            <div className='kyc-sub-heading-text-container' data-testid='kyc-text-retry'>
              <p className='kyc-sub-heading-text-disclaimer'>
                We are unable to complete your KYC verification due to issues encountered during the
                process.
              </p>
              <p className='kyc-sub-heading-text-disclaimer'>This could be because:</p>
              <ol className='ml-14'>
                <li>Our system was unable to automatically verify your details.</li>
                <li>Our compliance team manually rejected your KYC after review.</li>
              </ol>
              <p className='kyc-sub-heading-text-disclaimer mt-14'>
                Please ensure all submitted details and documents are accurate and meet the
                necessary requirements. If you believe this was a mistake or need further
                assistance, please contact our support team.
              </p>
            </div>
          )}
        </div>
        <SumSub kycStatus={kycStatusData} noOfKyc={noOfKyc} getKYC={getKYC} />

        <div className='kyc-sub-heading-text-container-note'>
          <p className='basic kyc-sub-heading-text-note'>
            Note: We currently accept individual investors only. If you are a corporate entity or
            institution, please contact your relationship manager or reach out to us via the Contact
            Us button below.
          </p>
        </div>

        <div className='kyc-footer'>
          <Flex variant='row-center' classes={['mt-10']}>
            <CTABanner
              heading={QUESTION_FOR_KYC}
              subHeading={GET_IN_TOUCH_WITH_ADMIN}
              ButtonComponent={
                <Button
                  classes={['auto-btn-width']}
                  variant='primary-btn'
                  text={CONTACT_US}
                  handleClick={handleClickModal}
                />
              }
            />
          </Flex>
          {showContactUsModal ? (
            <ContactUsModal active={true} subject={'General'} handleOnClose={handleClickModal} />
          ) : null}
        </div>
      </div>
    </>
  )
}

export { KYCPresenter }
