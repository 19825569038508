import React, { ReactNode, useState } from 'react'
import { Button } from '../Button'
import { Flex } from '../Flex'
import { H2, H4 } from '../Heading'
import { Close, DeleteIcon } from '../Icon'
import { Input } from '../Input/input-component'
import { Line } from '../Line'
import './input-modal-component.scss'

interface InputModalProps {
  children?: React.ReactNode
  testId?: string
}

function InputModal({ children, testId }: InputModalProps) {
  return (
    <div className='modal-container' data-testid={testId}>
      <>{children}</>
    </div>
  )
}

export { InputModal }
