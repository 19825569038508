import React from 'react'
import { IFormProps } from '../../lib/types'
import { GLogin } from './google-login-container'
import { FormikProps, Formik } from 'formik'
import {
  Input,
  Button,
  Line,
  Anchor,
  P,
  Flex,
  CheckboxInput,
  GoogleReCaptcha,
} from '../../lib/components'
import { PASSWORD_ERROR } from '../../lib/utils/message'

const LoginPresenter = ({
  initialValues,
  handleFormSubmit,
  validationSchema,
  reRef,
  googleLoginHandler,
}: IFormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {(formik) => {
        const {
          values,
          touched,
          errors,
          dirty,
          handleChange,
          handleSubmit,
          setFieldValue,
        }: FormikProps<typeof initialValues> = formik
        return (
          <form>
            <Input
              labelText='Email'
              name='email'
              placeholder='Enter your email'
              type='text'
              data-testid='email-input'
              required
              value={values.email}
              handleChange={handleChange(initialValues.email)}
              dirty={dirty}
              errors={{ email: !!errors && errors.email ? errors.email : null }}
              touched={{ email: !!touched && !!touched.email }}
            />
            <div className='mt-16'>
              <Input
                labelText='Password'
                name='password'
                placeholder=''
                required
                type='password'
                data-testid='password-input'
                value={values.password}
                infoText={PASSWORD_ERROR}
                handleChange={handleChange(initialValues.password)}
                dirty={dirty}
                errors={{
                  password: !!errors && errors.password ? errors.password : null,
                }}
                touched={{ password: !!touched && !!touched.password }}
              />
            </div>
            <Flex variant='space-between' classes={['mt-16']}>
              <Flex variant='row' classes={['mr-8']}>
                {/* TODO: Add remember me once session management is added */}
                {/* <CheckboxInput
                  labelText='Remember me'
                  name='rememberMe'
                  value={values.rememberMe}
                  handleChange={handleChange(initialValues.rememberMe)}
                /> */}
              </Flex>
              <Anchor text='Forgot your password?' href='/forgot-password' variant='basic-anchor' />
            </Flex>
            <Line />

            <GoogleReCaptcha
              handleChange={(value) => setFieldValue('reCaptchaToken', value)}
              reCaptchaRef={reRef}
              size='invisible'
            />

            <Button
              text='Log in'
              classes={['mb-24']}
              variant='primary-btn'
              handleClick={handleSubmit}
            />

            {googleLoginHandler && (
              <GLogin
                onFailure={(res: any) => console.log('Login Failed ', res)}
                onSuccess={(res: any) => googleLoginHandler(res, values.rememberMe)}
              />
            )}

            <Flex variant='row-center'>
              <P text='Don’t have an account?' classes={['mr-8']} variant='center' />
              <Anchor text='Sign up now' href='/signup' variant='basic-anchor' />
            </Flex>
          </form>
        )
      }}
    </Formik>
  )
}

export { LoginPresenter }
