/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react'
import './payment.scss'
import {
  H2,
  Span,
  Anchor,
  H4,
  Image,
  Line,
  InputFile,
  PendingIcon,
  Button,
  Input,
  H5,
  H6,
} from '../../lib/components'
import { displayDollar } from '../../lib/utils/helpers'
import bankDetails from './bank-details'
import { PLATFORM_FEE, PLATFORM_FEE_CURRENCY } from './constants'

const PaymentUploadDocument = ({
  project,
  investment,
  handleFileUpload,
  handleCompletePayment,
}: any) => {
  const [paymentCopy, setPaymentCopy] = useState(investment ? investment.paymentCopy : undefined)
  const [transactionNumber, setTransactionNumber] = useState<string>('')
  const fileData = {
    name: 'Payment copy',
    status: 'UPLOADED',
    lastModified:
      investment && investment.updatedAt && new Date(investment.updatedAt).getMilliseconds(),
  }
  const fileInfo = paymentCopy && paymentCopy.length > 0 ? fileData : []
  const isDisabled =
    !(paymentCopy && paymentCopy.length > 0) &&
    !(transactionNumber.length >= 10 && transactionNumber.length <= 20)

  const handleFileChange = async (file: any) => {
    if (!file) return
    const response = await handleFileUpload(file)
    if (response && response.status === 200) {
      setPaymentCopy(response.data.key)
    }
  }

  const handleTransactionNumberErrorMessage = (val: string): any => {
    const transactionNumber = val.trim()

    if (transactionNumber === '') {
      return {
        transactionNumber: 'Transaction number cannot be empty',
      }
    }

    const regex = /^[A-Za-z0-9- ]+$/
    if (!regex.test(transactionNumber)) {
      return {
        transactionNumber: 'Transaction number contains invalid characters',
      }
    }

    if (transactionNumber.length < 10) {
      return {
        transactionNumber: 'Transaction number must be more than 10 characters',
      }
    }

    if (transactionNumber.length > 20) {
      return {
        transactionNumber: 'Transaction number must be less than 20 characters',
      }
    }

    return {}
  }

  const getTotalAmount = (...amounts: (string | number)[]): string => {
    return amounts.reduce((acc: number, b: string | number) => acc + Number(b ?? 0), 0).toString()
  }

  return (
    <>
      <div className='payment-upload-doc-heading-container'>
        <H2 text='Transfer funds' />
        <div>
          <Span text={'Payment process for'} />
          <Anchor
            classes={['invest-header-project-title']}
            text={project.projectName}
            href={`/opportunity/${project?.projectSlug}/${project?.id}`}
            variant='basic-anchor'
          />
        </div>
      </div>
      <div
        data-testid='invest-funds-transfer-payment-warning'
        className='payment-warning-container-full mt-12 mb-12'
      >
        <PendingIcon classes={['info-icon']} width='75px' height='24px' />
        Please note that investors are responsible for bearing all SWIFT charges related to their
        transactions. Credibila will not cover or reimburse SWIFT charges under any circumstances.
        When initiating a wire transfer, please ensure you select the appropriate options to bear
        the SWIFT charges yourself.
      </div>
      <div className='payment-upload-doc-user-wrapper'>
        <div className='payment-upload-doc-user-wrapper-left'>
          <H4 classes={['h4-heading']} text='Wire transfer details' />

          <div className='payment-upload-doc-user-container'>
            <table className='payment-upload-doc-user-table'>
              <tbody>
                {Object.keys(bankDetails.usdClient).map((k) => (
                  <tr
                    key={k}
                    className={
                      k === 'purposeOfTransaction'
                        ? 'payment-upload-doc-user-table-row__caution-row'
                        : ''
                    }
                  >
                    <td>{bankDetails.usdClient[k as keyof typeof bankDetails.usdClient].title}</td>
                    <td>{bankDetails.usdClient[k as keyof typeof bankDetails.usdClient].value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='acknowledgement-container'>
            <H4 text='Swift copy or bank acknowledgement' />
            <span className='sub-heading'>{'Please upload the payment slip below.'} </span>
            <InputFile
              setRemoveFile={() => setPaymentCopy('')}
              type='doc'
              label=''
              testId='invest-upload-file-input'
              name='swiftCopyBank'
              handleFiles={handleFileChange}
              fileInfo={fileInfo}
            />
            <H5 text='OR' classes={['align-center', 'mt-20', 'mb-20']} />
            <H6 text='Enter Transaction Reference Number:' classes={['mb-12']} />
            <Input
              type='text'
              name='transactionNumber'
              placeholder='Transaction Reference Number'
              data-testid='transaction number-input'
              value={transactionNumber}
              handleChange={(e) => {
                setTransactionNumber(e.target.value)
              }}
              errors={handleTransactionNumberErrorMessage(transactionNumber)}
              touched={{ transactionNumber: transactionNumber.length > 0 }}
              infoText={
                'Please enter a valid Transaction number between 10 and 20 characters, if your bank has not provided you with the SWIFT copy'
              }
            />
          </div>
          <div
            className='payment-warning-container'
            data-testid='invest-funds-transfer-decision-allocation-warning'
          >
            <PendingIcon classes={['info-icon']} width='24px' height='24px' />
            The investment is subject to allocation decision of the fund manager and availability of
            allocation
          </div>
          <div className='payment-button-container'>
            <Button
              text='Complete payment'
              isDisabled={isDisabled}
              handleClick={() => {
                handleCompletePayment(paymentCopy, transactionNumber)
              }}
              variant='primary-btn'
            />
          </div>
        </div>
        <div className='payment-upload-doc-user-wrapper-right'>
          <div className='payment-company-card'>
            <span className='summary-label'> {'Summary'} </span>
            <div className='company-image-container'>
              <Image source={project.projectCover} radius='r8' variant='round' />
              <span className='company-name'> {project.projectName} </span>
            </div>
            <div className='property-container'>
              <div className='property-row'>
                <span className='property-column-left' data-testid='investment-amount-text'>
                  Investment amount:
                </span>
                <span className='property-column-right' data-testid='investment-amount-value'>
                  {displayDollar(investment?.amount, project.currencyCode, 2, true)}
                </span>
              </div>
              <div className='property-row'>
                <span className='property-column-left' data-testid='platform-fee-text'>
                  Platform fee:
                </span>
                <span className='property-column-right' data-testid='platform-fee-value'>
                  {displayDollar(
                    ((investment?.platform_fee?.amount ?? PLATFORM_FEE) * 100).toString(),
                    PLATFORM_FEE_CURRENCY,
                  )}
                </span>
              </div>
            </div>
            <Line />
            <div className='property-container'>
              <div className='property-row'>
                <span className='property-column-left' data-testid='total-amount-text'>
                  Total amount
                </span>
                <span className='property-column-right font-bold' data-testid='total-amount-value'>
                  {displayDollar(
                    getTotalAmount(
                      investment?.amount,
                      (investment?.platform_fee?.amount ?? PLATFORM_FEE) * 100,
                    ),
                    project.currencyCode,
                    2,
                    true,
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { PaymentUploadDocument }
