import React from 'react'
import { Formik, FormikProps } from 'formik'
import { Button } from '../Button'
import { Input } from '../Input'
import { TextboxInput } from '../Input/input-component'
import { SelectDropdown } from '../SelectDropdown'
import { ContactUsFormProps } from '../../types'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { GoogleReCaptcha } from '../ReCaptcha'
import { CONTACT_US_ASSISTANCE_CATEGORIES as SUBJECT_CATEGORIES } from '../../utils/constants'

const ContactUsForm = ({
  initialValues,
  validationSchema,
  handleSubmit,
  reRef,
  captchaToken,
  setCaptchaToken,
}: ContactUsFormProps) => {
  const { authToken, email } = useSelector((state: RootState) => state.investor)
  const isLoggedIn = authToken && email && authToken.length > 0 ? true : false
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          const {
            values,
            touched,
            errors,
            dirty,
            handleChange,
            handleSubmit,
            setFieldValue,
          }: FormikProps<typeof initialValues> = formik
          return (
            <form>
              <div className='contact-us-input-row'>
                <div className='contact-us-input-group'>
                  <Input
                    labelText='First Name'
                    name='firstName'
                    placeholder='Enter your first name'
                    type='text'
                    required
                    value={values.firstName}
                    handleChange={handleChange}
                    dirty={dirty}
                    errors={{
                      firstName: errors?.firstName ?? null,
                    }}
                    touched={{ firstName: !!touched?.firstName }}
                  />
                </div>
                <div className='contact-us-input-group'>
                  <Input
                    labelText='Last Name'
                    name='lastName'
                    placeholder='Enter your last name'
                    type='text'
                    required
                    value={values.lastName}
                    handleChange={handleChange}
                    dirty={dirty}
                    disabled={isLoggedIn}
                    errors={{
                      lastName: errors?.lastName ?? null,
                    }}
                    touched={{ lastName: !!touched?.lastName }}
                  />
                </div>
              </div>

              <div className='contact-us-input-row'>
                <div className='contact-us-input-group'>
                  <Input
                    labelText='Email Address'
                    name='email'
                    placeholder='Enter your email address'
                    type='email'
                    required
                    value={values.email}
                    handleChange={handleChange}
                    dirty={dirty}
                    disabled={isLoggedIn}
                    errors={{
                      email: !!errors && errors.email ? errors.email : null,
                    }}
                    touched={{ email: !!touched && !!touched.email }}
                  />
                </div>
                <div className='contact-us-input-group'>
                  <div className='mt-8'>{'Subject'}</div>
                  <SelectDropdown
                    name='subject'
                    id='subject'
                    formId=''
                    list={SUBJECT_CATEGORIES}
                    value={values.subject}
                    handleChange={(val) => {
                      setFieldValue('subject', val)
                    }}
                  />
                </div>
              </div>

              <TextboxInput
                name='message'
                labelText='Message'
                placeholder='Enter the message'
                required
                value={values.message}
                handleChange={handleChange}
                dirty={dirty}
                errors={{
                  message: !!errors && errors.message ? errors.message : null,
                }}
                touched={{
                  message: !!touched && !!touched.message,
                }}
              />

              <div className='contact-us-input-row'>
                <div className='g-captcha'>
                  <GoogleReCaptcha
                    handleChange={(value) => setCaptchaToken && setCaptchaToken(value)}
                    reCaptchaRef={reRef}
                    version='v2'
                  />
                </div>
                <div className='contact-us-submit'>
                  <Button
                    isDisabled={!captchaToken}
                    text='Submit'
                    classes={['mb-8 mt-14']}
                    variant='primary-btn'
                    handleClick={handleSubmit}
                  />
                </div>
              </div>
            </form>
          )
        }}
      </Formik>
    </>
  )
}

export { ContactUsForm }
