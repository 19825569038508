export default {
  usdClient: {
    beneficiaryName: {
      title: 'BENEFICIARY NAME:',
      value: 'Credibila Markets',
    },
    cif: { title: 'CIF:', value: '00900102178' },
    iban: { title: 'IBAN:', value: 'MU80STCB1170000000812384000USD' },
    accountNumber: { title: 'ACCOUNT NUMBER:', value: '50100000812384' },
    beneficiaryBank: {
      title: 'BENEFICIARY’S BANK:',
      value: 'SBM Bank (Mauritius) Limited',
    },
    bankAddress: {
      title: 'BANK ADDRESS:',
      value: '6th Floor SBM Tower, Port Louis',
    },
    swiftCode: { title: 'SWIFT CODE:', value: 'STCBMUMU' },
    intermediaryBank: {
      title: 'INTERMEDIARY / CORRESPONDENT BANK:',
      value: 'Citibank NA, New York',
    },
    intermediaryAccountNumber: { title: 'ACCOUNT NUMBER:', value: '36004679' },
    intermediarySwiftCode: { title: 'SWIFT CODE:', value: 'CITI US 33' },
    purposeOfTransaction: {
      title: 'PURPOSE OF TRANSACTION:',
      value: 'Client Deposit into Credibila',
    },
  },
}
