import { createSlice } from '@reduxjs/toolkit'
import { investorState } from '../lib/types'
import {
  removeInvestorDataFromLS,
  setInvestorDataToSS,
  getInvestorDataFromSS,
  removeInvestorDataFromSS,
} from '../lib/utils/local-storage'

const getInitialData = () => {
  const { authToken, investorData } = getInvestorDataFromSS()
  if (!!authToken && !!investorData) {
    return {
      authToken: authToken,
      name: investorData.name,
      email: investorData.email,
      userId: investorData.userId,
      role: investorData.role,
      isEmailVerified: investorData.isEmailVerified,
      acceptedNDA: investorData.acceptedNDA,
      kycStatus: investorData.kycStatus,
      rememberMe: investorData.rememberMe,
      isActiveSession: investorData.isActiveSession,
    }
  }

  return {
    authToken: '',
    name: '',
    email: '',
    userId: 0,
    role: '',
    isEmailVerified: false,
    acceptedNDA: false,
    kycStatus: '',
    rememberMe: false,
    isActiveSession: false,
  }
}
const initialState = getInitialData()

const investorSlice = createSlice({
  name: 'investor',
  initialState,
  reducers: {
    createConfiguration: (state: investorState, action: any) => {
      ;(state.authToken = action.payload.authToken),
        (state.name = action.payload.name),
        (state.email = action.payload.email),
        (state.userId = action.payload.userId),
        (state.role = action.payload.role),
        (state.isEmailVerified = action.payload.isEmailVerified)
      ;(state.acceptedNDA = action.payload.acceptedNDA),
        (state.kycStatus = action.payload.kycStatus),
        (state.rememberMe = action.payload.rememberMe || false),
        (state.isActiveSession = action.payload.isActiveSession)

      const { authToken, investorData } = getInvestorDataFromSS()
      if (!authToken && !investorData && action.payload.authToken && !action.payload.rememberMe)
        setInvestorDataToSS(state)
      return state
    },
    resetConfiguration: (state: investorState) => {
      if (state.rememberMe) removeInvestorDataFromLS()
      else removeInvestorDataFromSS()
      return initialState
    },
    updateConfiguration: (state: investorState, action: { payload: any }) => {
      const newState = {
        ...state,
        [action.payload.name]: action.payload.value,
      }
      setInvestorDataToSS(newState)
      return newState
    },
  },
})

export { investorSlice }
export const { createConfiguration, resetConfiguration, updateConfiguration } =
  investorSlice.actions

export default investorSlice.reducer
