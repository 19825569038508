import React, { useCallback, useState } from 'react'
import { InputProps, CheckboxProps, RadioInputProps, TextAreaProps } from '../../types'
import './input-component.scss'
import { ERROR, SUCCESS } from '../../utils/message'
import { StatusLabel } from '../StatusLabel'
import { CheckCircleIcon, ReportIcon, EyeIcon, SlashedEyeIcon } from '../Icon/icon-component'

const Input = ({
  type,
  labelText,
  name,
  value,
  infoText,
  handleChange,
  handleFocus,
  ref,
  required,
  placeholder,
  errors,
  touched,
  disabled,
  maxValue,
  dirty,
  handleKeyPress,
  keyToTrigger,
  ...props
}: InputProps) => {
  const isError = errors && errors[name] && touched && touched[name]
  const isValid = !errors && dirty
  const [hide, setHide] = useState(true)
  const getClassName = useCallback(() => {
    if (isValid) return 'success-input input-field'
    else if (isError) return 'error-input input-field'
    else return 'input-field'
  }, [errors])

  const setType = useCallback(() => {
    if (type !== 'password') {
      return type
    }
    if (type === 'password') {
      return hide ? type : 'text'
    }
  }, [hide, type])

  const getMessageType = useCallback(() => {
    if (isValid) {
      return SUCCESS
    }
    if (isError) {
      return ERROR
    }
    return SUCCESS
  }, [errors])
  const handleIconClick = () => {
    setHide(!hide)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === keyToTrigger) {
      handleKeyPress && handleKeyPress()
    }
  }
  return (
    <>
      {labelText && (
        <div data-testid={labelText} className='input-label'>
          {labelText}
        </div>
      )}
      <input
        name={name}
        {...props}
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        ref={ref}
        className={getClassName()}
        placeholder={placeholder}
        type={setType()}
        required={required}
        disabled={disabled}
        max={maxValue}
        onKeyDown={handleKeyDown}
      />
      <span className='input-icon'>
        {isValid && <CheckCircleIcon height='20' width='20' type={getMessageType()} />}
        {isError && <ReportIcon height='20' width='20' type={getMessageType()} />}
        {hide && !isError && type === 'password' && (
          <EyeIcon type='default-light' height='24' width='24' onClick={handleIconClick} />
        )}
        {!hide && !isError && type === 'password' && (
          <SlashedEyeIcon type='default-light' height='24' onClick={handleIconClick} width='24' />
        )}
      </span>
      {(isError || infoText) && (
        <div className='mt-8'>
          {isError && <StatusLabel type={getMessageType()} variant='basic' text={errors[name]} />}
          {!isError && infoText && <StatusLabel type='info' variant='basic' text={infoText} />}
        </div>
      )}
    </>
  )
}

const CheckboxInput = ({
  name,
  labelText,
  value,
  checked = false,
  handleChange,
  disabled = false,
}: CheckboxProps) => {
  return (
    <div className='input-checkbox-container'>
      {checked ? (
        <input
          className='input-checkbox'
          checked
          name={name}
          type={'checkbox'}
          value={value}
          onChange={handleChange}
          disabled={disabled}
        />
      ) : (
        <input
          className='input-checkbox'
          name={name}
          type={'checkbox'}
          value={value}
          onChange={handleChange}
          disabled={disabled}
        />
      )}
      <span className='input-checkbox-label'> {labelText} </span>
    </div>
  )
}

const TextboxInput = ({
  labelText,
  name,
  value,
  handleChange,
  required,
  placeholder,
  errors,
  touched,
  dirty,
}: TextAreaProps) => {
  const isError = errors && errors[name] && touched && touched[name]
  const isValid = !errors && dirty
  const getClassName = useCallback(() => {
    if (isValid) return 'success-input input-field'
    else if (isError) return 'error-input input-field'
    else return 'input-field'
  }, [errors])

  const getMessageType = useCallback(() => {
    if (isValid) {
      return SUCCESS
    }
    if (isError) {
      return ERROR
    }
    return SUCCESS
  }, [errors])
  return (
    <>
      {labelText && <div className='input-label'> {labelText}</div>}
      <textarea
        placeholder={placeholder}
        required={required}
        className={getClassName()}
        name={name}
        value={value}
        onChange={handleChange}
      />
      <span className='input-icon'>
        {isValid && <CheckCircleIcon height='20' width='20' type={getMessageType()} />}
        {isError && <ReportIcon height='20' width='20' type={getMessageType()} />}
      </span>
      {isError && <StatusLabel type={getMessageType()} variant='basic' text={errors[name]} />}
    </>
  )
}

export { Input, CheckboxInput, TextboxInput }
