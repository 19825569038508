import React from 'react'
import './button-component.scss'
import { ButtonProps } from '../../types'
import { concatClassName } from '../../utils/helpers'

const Button = ({
  iconVariant = 'left',
  IconComponent,
  text,
  variant,
  handleClick,
  buttonType = 'button',
  isDisabled = false,
  classes,
  testId,
}: ButtonProps) => {
  return (
    <>
      {iconVariant === 'left' && (
        <button
          className={`${variant} btn ${concatClassName(classes)}`}
          type={buttonType}
          data-testid={testId}
          onClick={handleClick}
          {...(isDisabled ? { disabled: true } : {})}
        >
          {IconComponent} {text}
        </button>
      )}
      {iconVariant === 'right' && (
        <button
          className={`${variant} btn ${concatClassName(classes)}`}
          data-testid={testId}
          onClick={handleClick}
          disabled={isDisabled}
        >
          {text} {IconComponent}
        </button>
      )}
    </>
  )
}
export { Button }
