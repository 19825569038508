import React from 'react'
import {
  Button,
  LeftArrowIcon,
  Span,
  Flex,
  P,
  Tag,
  AnchorLabel,
  LinkIcon,
  LocationIcon,
  DollarIcon,
  H2,
  Image,
} from '../../lib/components'
import { BACK_TO_PROJECT } from '../../lib/utils/message'
import { ProjectDetailProps } from '../../lib/types'
import { useBreakpoint } from '../../lib/utils/hooks'
import { LG } from '../../lib/utils/constants'
import { Video } from '../../lib/components/Video'

const OpportunityDetailTop = ({
  project,
  investHandleClick,
  backToProjectHandler,
  investButtonProperty,
  kycStatus,
}: ProjectDetailProps) => {
  const { breakpoint } = useBreakpoint()
  return (
    <>
      <div>
        <Flex classes={['back-button-container']}>
          <Button
            classes={['auto-small-btn-width']}
            variant='secondary-btn'
            handleClick={backToProjectHandler}
            IconComponent={<LeftArrowIcon />}
          />
          <Span classes={['ml-16', 'font-16', 'back-button']} text={BACK_TO_PROJECT} />
        </Flex>
        <Flex classes={['mt-24', 'flex-align-center']}>
          <H2 classes={['mr-16 opportunity-details-heading']} text={project?.projectName || ''} />
        </Flex>
        {Number(project?.noOfInvestors) > 0 && (
          <Flex classes={['mt-24', 'project-sub-heading', 'investors-backed']}>
            <P
              variant='default-blue'
              text={`Backed by ${project?.noOfInvestors} ${
                Number(project?.noOfInvestors) === 1 ? 'investor' : 'investors'
              }`}
            />
          </Flex>
        )}
        <Flex classes={['mt-16', 'project-sub-info']}>
          <AnchorLabel
            href={''}
            text={project?.location || ''}
            isDisabled={true}
            variant={'label-anchor'}
            IconComponent={<LocationIcon />}
          />

          <AnchorLabel
            classes={['ml-8']}
            href={''}
            text={project?.currencyCode || ''}
            isDisabled={true}
            variant={'label-anchor'}
            IconComponent={<DollarIcon />}
          />

          {project?.companyWebsite && (
            <AnchorLabel
              classes={['ml-8']}
              href={project?.companyWebsite || ''}
              text={project?.companyWebsite || ''}
              isDisabled={false}
              variant={'label-anchor'}
              IconComponent={<LinkIcon />}
              underline={true}
            />
          )}
          {project?.assetClass && <Tag classes={['margin-4']} text={`${project?.assetClass}`} />}
        </Flex>

        <Flex classes={['flex-row-center', 'mt-32']}>
          <Flex
            variant='column'
            classes={
              !project?.isHideCompanyDetails
                ? ['top-company-details']
                : ['top-company-details', 'width-auto']
            }
          >
            <P text={project?.projectSummary} textSize='regular' variant='default-gray' />
          </Flex>
        </Flex>
        <div className='project-detail-img-container-property-section'>
          <Flex classes={['btn-div', 'mt-32']}>
            <Button
              classes={[breakpoint && breakpoint < LG ? '' : 'auto-btn-width']}
              isDisabled={investButtonProperty && investButtonProperty.isDisable}
              text={investButtonProperty && investButtonProperty.buttonText}
              testId='opp-detail-top-section-invest-btn'
              variant='primary-btn'
              handleClick={() => {
                if (investButtonProperty && !investButtonProperty.isDisable)
                  investHandleClick(project.projectSlug, project.id)
              }}
            />
          </Flex>
          <Flex classes={['mt-8']}>
            {kycStatus === 'APPROVED' &&
              investButtonProperty &&
              investButtonProperty.investmentInfo &&
              investButtonProperty.investmentInfo.props.children && (
                <div
                  className='kyc-sub-heading max-width: 550px;'
                  data-testid='opp-detail-top-section-invest-info'
                >
                  <p>{investButtonProperty.investmentInfo}</p>
                </div>
              )}
          </Flex>
        </div>
      </div>
      <div>
        {project?.video?.length > 0 ? (
          <Video url={project.video} classes={['project-cover-video', 'mt-50']} />
        ) : (
          <Image
            classes={['project-cover-image', 'mt-50']}
            source={project.cover_image ?? project.productImages[0]}
          />
        )}
      </div>
    </>
  )
}

export { OpportunityDetailTop }
