import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Card } from '../Card'
import { InputModal } from '../InputModal'
import { TransactionChecklistForm } from './transaction-checklist-modal-form'
import { kycServices } from '../../../services'
import { RootState } from '../../../store'
import './transactionChecklistModal.scss'
import { PendingIcon } from '../Icon'

const TransactionChecklist = ({ active, handleOnClose }: any) => {
  const { authToken, userId } = useSelector((state: RootState) => state.investor)
  const [isChecked, setChecked] = useState<boolean>(false)
  const [portfolioID, setPortfolioID] = useState<string>('')
  const handleCheckboxClick = async () => {
    const data: any = await kycServices.acceptTransactionChecklistAgreement(
      { declaration: true },
      {
        Authorization: authToken ? authToken : '',
      },
    )

    if (data && data.data && data.status === 200) {
      setChecked(!isChecked)
      setTimeout(() => handleOnClose(), 1000)
    }
  }

  useEffect(() => {
    if (active && !isChecked) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'auto'
  }, [active, isChecked])

  useEffect(() => {
    ;(async () => {
      const response: any = await kycServices.getKYC_v1(userId.toString(), {
        Authorization: authToken ? authToken : '',
      })
      if (!!response && response.data && response.status === 200) {
        setChecked(response.data.transaction_checklist_agreement)
        setPortfolioID(response.data.investor_portfolio_id)
      }
    })()
  }, [isChecked])

  const handleModalBgClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget && isChecked) {
      handleOnClose()
    }
  }

  return active ? (
    <InputModal testId='transaction-checklist-modal'>
      <div
        data-testid='transaction-checklist-modal-card-container'
        onClick={handleModalBgClick}
        className='modal-card-container'
      >
        <Card classes={['p-24 transaction-checklist-modal']}>
          <div
            className='self-declaration-wrapper self-declaration-wrapper-overflow mb-24'
            style={{ maxHeight: 'calc(100% - 110px)' }}
          >
            <div className='self-declaration-wrapper-text'>
              <h1 className='text-align-center mb-24' style={{ lineHeight: '120%' }}>
                Transaction Checklist
              </h1>
              <p>
                Please review the following guidelines and checklist to ensure a smooth transaction
                process when transferring funds to Credibila:
              </p>
              <br />

              <p>
                <b>
                  Ensure all beneficiary details (name, account number, bank name, etc.) are
                  correct.
                </b>
              </p>
              <p>Double-check to avoid errors</p>
              <br />

              <p>
                <b>Purpose of Transaction:</b>
              </p>
              <p>
                Mention the purpose of the transaction as “Funding of client account Portfolio No
                -&nbsp; {portfolioID}”.
              </p>
              <br />

              <p>
                <b>Transaction Completion:</b>
              </p>
              <div
                data-testid='invest-funds-transfer-payment-warning'
                className='payment-warning-container-fit-content mt-12 mb-12'
              >
                <PendingIcon classes={['info-icon']} width='75px' height='24px' />
                Upload the SWIFT copy on the platform and mark the transaction as completed. If your
                bank does not provide a SWIFT copy, include the transaction reference number.
              </div>
              <br />

              <p>
                <b>Error Resolution:</b>
              </p>
              <p>If there are any issues, our team may request an MT199 form from your bank.</p>
              <br />

              <p>
                <b>Personal Information Security:</b>
              </p>
              <p>
                Credibila staff will never request your personal bank details or OTPs. Only your
                Relationship Manager (RM), whose contact details are on your dashboard and in
                official Credibila emails, will reach out to you.
              </p>
              <br />

              <p>
                <b>SWIFT Charges:</b>
              </p>
              <p>
                Investors are responsible for all SWIFT charges. Credibila does not cover or
                reimburse these costs.
              </p>
              <ol className='checklist-ordered-list'>
                <li>
                  <b>For Deposits:</b> Select the appropriate option during your transfer to bear
                  these charges yourself.
                </li>
                <li>
                  <b>For Withdrawals:</b> SWIFT charges will also be borne by the investor.
                  Credibila will deduct these charges from the withdrawal amount and send the
                  remaining balance to your account.
                </li>
                <li>
                  Additionally, you must upload the SWIFT copy as proof of payment. If your bank
                  does not issue a SWIFT copy, please provide the transaction reference number on
                  the platform and complete the investment steps. Funds will not be processed for
                  further transfer unless this requirement is fulfilled through the application.
                </li>
              </ol>
              <br />

              <p>
                <b>Bank Account Matching:</b>
              </p>
              <div
                data-testid='invest-funds-transfer-payment-warning'
                className='payment-warning-container-fit-content mt-12 mb-12'
              >
                <PendingIcon classes={['info-icon']} width='75px' height='24px' />
                The bank details submitted at the time of source of funds must be the same bank
                account mentioned in the SWIFT copy for the bank transfer. This same bank account
                must also be used for the eventual withdrawal of your investment.
              </div>
              <br />

              <p>
                <b>In Case of Mismatched or Closed Accounts:</b>
              </p>
              <p>
                If the bank account details do not match, or if the bank account is closed, you must
                provide:
              </p>
              <ol className='checklist-ordered-list'>
                <li>A NOC (No Objection Certificate) letter from the bank.</li>
                <li>An account closure letter from the bank.</li>
              </ol>
              <br />

              <p>
                You cannot withdraw to a different account unless your first account is closed, and
                you provide us with the account closure letter along with a new letter showing that
                the new account has been opened in your name.
              </p>
              <br />

              <p>
                <b>Transfer/Receiving Restrictions:</b>
              </p>
              <p>
                You are not allowed to transfer/receive money from any account other than your own.
                If your bank account details are updated, provide the necessary documentation, such
                as account closure and new account opening letters.
              </p>
            </div>
          </div>
          <TransactionChecklistForm
            handleCheckboxClick={handleCheckboxClick}
            isChecked={isChecked}
          />
        </Card>
      </div>
    </InputModal>
  ) : null
}
export { TransactionChecklist }
