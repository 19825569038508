import { Verification } from '../lib/types'
export const BASE_URL = process.env.REACT_APP_GBC_BASE_URL || 'http://0.0.0.0:4000'
// User and auth routes
export const USER_CREATE = `${BASE_URL}/user/signup`
export const USER_SIGN_IN = `${BASE_URL}/auth/login`
export const GOOGLE_SIGN_IN = `${BASE_URL}/auth/login-with-google`
export const SIGN_OUT = `${BASE_URL}/auth/logout`
export const VERIFY_EMAIL = (code: string, user: string, type: Verification) =>
  `${BASE_URL}/user/verify-email?user=${user}&code=${code}&type=${type}`
export const SEND_VERIFICATION_LINK = `${BASE_URL}/user/resend/verify-email`
export const RESET_PASSWORD_TOKEN_VALID = (code: string, userId: string) =>
  `${BASE_URL}/v1/user/reset-password/token/validate?code=${code}&userId=${userId}`
export const RESET_PASSWORD = `${BASE_URL}/user/reset-password`
export const USER_LOGOUT = `${BASE_URL}/auth/logout`
export const UPLOAD_FILE = `${BASE_URL}/file/upload`
export const GET_PROJECT_BY_ID = `${BASE_URL}/project`
export const SEARCH_PROJECTS = `${BASE_URL}/project/search`
export const SAVED_FOR_LATER = `${BASE_URL}/project/save`
export const GET_ALL_PROJECTS = `${BASE_URL}/project/all`
export const KYC_SUBMIT = `${BASE_URL}/kyc/submit`
export const KYC_UPDATE = `${BASE_URL}/kyc/update`
export const GET_KYC = (id: string) => `${BASE_URL}/kyc/${id}`
export const CONTACT_US_SUBMIT = `${BASE_URL}/contact-us/submit`
export const LOGGED_IN_USER = `${BASE_URL}/logged-in-user`
export const UPDATE_KYC_DOCUMENT = (id: string) => `${BASE_URL}/kyc/document/${id}/update`
export const UPDATE_PROFILE = `${BASE_URL}/user/update`
export const GET_PROFILE = `${BASE_URL}/logged-in-user`
export const ACCEPT_NDA = `${BASE_URL}/kyc/accept-nda`
export const HOME = `${BASE_URL}/home`
export const GENERATE_SUMSUB_ACCESS_TOKEN = `${BASE_URL}/kyc/generate-auth-token`
export const GENERATE_SUMSUB_SDK_LINK = `${BASE_URL}/v1/kyc/sumsub/generate/sdk-link`

export const GET_INVESTMENT_BY_PROJECT_ID = `${BASE_URL}/investments`
export const POST_PROJECT_INVESTMENT = `${BASE_URL}/investments/invest`
export const UPDATE_PROJECT_INVESTMENT = `${BASE_URL}/investments/update`
export const GET_USER_INVESTMENT = `${BASE_URL}/investments/user/details`
export const SEARCH_INVESTMENTS = `${BASE_URL}/investments/search`
export const GET_INVESTMENT_METRICS = `${BASE_URL}/investments/metrics`
export const GET_QUARTERLY_REPORT = `${BASE_URL}/investments/quaterly-reports`
export const PREVIEW_FILE = `${BASE_URL}/file/preview`
export const GET_INVESTMENT_LATEST = `${BASE_URL}/investments/latest`

export const GET_FEATURED_PROJECT = `${BASE_URL}/project/featured`
export const GET_LAST_MINUTE_PROJECTS = `${BASE_URL}/project/lastminute`

// v1 routes
export const USER_CREATE_V1 = `${BASE_URL}/v1/user/signup`
export const VERIFY_EMAIL_V1 = (code: string, investorId: string, type: Verification) =>
  `${BASE_URL}/v1/user/verify-email?investorId=${investorId}&code=${code}&type=${type}`
export const SEND_VERIFICATION_LINK_V1 = `${BASE_URL}/v1/user/resend/verify-email`
export const RESET_PASSWORD_V1 = `${BASE_URL}/v1/user/reset-password`
export const USER_SIGN_IN_V1 = `${BASE_URL}/v1/auth/login`
export const USER_LOGOUT_V1 = `${BASE_URL}/v1/auth/logout`
export const GET_ALL_OPPORTUNITY = `${BASE_URL}/v1/opportunity/all`
export const SEARCH_OPPORTUNITY_V1 = `${BASE_URL}/v1/opportunity/search`
export const ACCEPT_NDA_V1 = `${BASE_URL}/v1/kyc/accept-nda`
export const ACCEPT_INVESTMENT_ESSENTIAL_AGREEMENT = `${BASE_URL}/v1/kyc/accept-investment-essentials-agreement`
export const ACCEPT_TOB = `${BASE_URL}/v1/kyc/accept-tob`
export const ACCEPT_TRANSACTION_CHECKLIST_AGREEMENT = `${BASE_URL}/v1/kyc/accept-transaction-checklist-agreement`
export const GET_OPPORTUNITY_BY_ID = (id: string) =>
  `${BASE_URL}/v1/opportunity/${id}?status=PUBLISHED`
export const GET_KYC_V1 = (id: string) => `${BASE_URL}/v1/kyc/${id}`
export const GET_USER_INVESTMENT_V1 = `${BASE_URL}/v1/investments/investor`
export const GET_INVESTMENT_LATEST_V1 = `${BASE_URL}/v1/investments/latest`
export const GET_INVESTMENT_BY_OPPORTUNITY_ID = `${BASE_URL}/v1/investments`
export const LOGGED_IN_USER_V1 = `${BASE_URL}/v1/logged-in-user`
export const GET_PROFILE_V1 = `${BASE_URL}/v1/logged-in-user`
export const ADD_OPPORTUNITY_INVESTMENT = `${BASE_URL}/v1/investments`
export const UPDATE_OPPORTUNITY_INVESTMENT = `${BASE_URL}/v1/investments`
export const UPDATE_PROFILE_V1 = `${BASE_URL}/v1/user`
export const SEARCH_INVESTMENTS_V1 = `${BASE_URL}/v1/investments/search`
export const GET_INVESTMENT_METRICS_V1 = `${BASE_URL}/v1/investments/metrics`
export const GET_INVESTMENT_DOCUMENT = (id: string) => `${BASE_URL}/v1/investments/document/${id}`
export const GET_SIGNED_DOCUMENT = `${BASE_URL}/v1/investments/document`
export const GET_DOCUMENT_ACCESS_TOKEN = `${BASE_URL}/v1/documents/access-token`
export const GET_DOCUMENT_SIGNING_LINK = `${BASE_URL}/v1/documents/signing-link`
export const GET_DOCUMENT_DOWNLOAD_LINK = `${BASE_URL}/v1/documents/download-link`
export const CONTACT_US_INVESTOR_SUBMIT_V1 = `${BASE_URL}/v1/contact-us/submit`
export const CONTACT_US_GUEST_SUBMIT_V1 = `${BASE_URL}/v1/contact-us/guest/submit`
export const GET_INVESTOR_WITH_KYC = `${BASE_URL}/v1/kyc/investor-with-kyc`
export const GOOGLE_SIGN_IN_V1 = `${BASE_URL}/v1/auth/login-with-google`
export const GET_FEATURED_OPPORTUNITY = `${BASE_URL}/v1/opportunity/featured`
export const UPDATE_CRM = `${BASE_URL}/v1/crm/update`
export const ADD_CRM_EVENT = `${BASE_URL}/v1/crm/event`
export const GET_DASHBOARD_SUMMARY = `${BASE_URL}/v1/dashboard/summary`
export const GET_INVESTMENT_SUMMARY = (opportunityId: string) =>
  `${BASE_URL}/v1/dashboard/summary/${opportunityId}`
export const GET_DASHBOARD_INVESTOR_HOLDINGS = `${BASE_URL}/v1/dashboard/holdings`
export const GET_DASHBOARD_ACTIVE_INVESTMENTS = `${BASE_URL}/v1/dashboard/investments/active`
export const GET_DASHBOARD_ACTIVE_INVESTMENTS_BY_OPPORTUNITY_ID = (opportunityId: string) =>
  `${BASE_URL}/v1/dashboard/investments/active/${opportunityId}`
export const GET_DASHBOARD_TRANSACTIONS_BY_OPPORTUNITY_ID = (opportunityId: string) =>
  `${BASE_URL}/v1/transaction/${opportunityId}`
